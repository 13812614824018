<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>عرض</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />

          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="البحث..."
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refCourseListTable"
        class="position-relative"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :items="allItem"
        striped
        show-empty
        empty-text="  يتم جلب البيانات"
      >
        <!-- Column: Status -->

        <template #cell(id)="data">
          <b-form-checkbox
            plain
            class="vs-checkbox-con"
            @change="Genertae(data.item.id)"
          >
            <span class="vs-checkbox--check">
              {{ data.item.id }}
            </span>
            <!-- <span class="vs-checkbox">

          </span> -->
          </b-form-checkbox>
        </template>

        <!-- Column: Actions -->
        <!-- <template #cell(actions)="data">
          <a role="button" @click="DeleteCourse(data.item.id)">
            <feather-icon icon="TrashIcon"
          /></a>
        </template> -->

      </b-table>
      <b-col
        cols="12"
        class="mt-50"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mr-1"
          @click="save"
        >
          <span>إنشاء دورة  اونلاين</span>
        </b-button>
      </b-col>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted">
              عرض {{ dataMeta.from }} الى {{ dataMeta.to }} من {{ dataMeta.of }} المدخلات
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalCategories"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BDropdownItemButton,
  BFormCheckbox,

} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import {
  ref, computed, watch, reactive,
} from '@vue/composition-api'
import Vue from 'vue'
import store from '@/store'
// import useCousresList from './useCousresList'
// import userStoreModule from '../userStoreModule'

export default {
  components: {
    // useCousresList,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BDropdownItemButton,
    BFormCheckbox,
    vSelect,
  },
  setup() {
    const coursesList = ref([])
    const meta = reactive({})
    const selected = ref([])
    const perPageOptions = [10, 25, 50, 100, 1]
    const perPage = ref(10)
    const refCourseListTable = ref(null)
    const totalCategories = ref(0)
    const searchQuery = ref('')
    const allItem = ref([])
    const currentPage = ref(1)
    const Genertae = id => {
      selected.value.push(id)
    }
    const GetAllCourses = (ctx, callback) => {
      store
        .dispatch('courses/getGenerateCourse', {
          'filter[search]': searchQuery.value,

          per_page: perPage.value,
          page: currentPage.value,
        })
        .then(response => {
          // coursesList.value=response.data.data
          allItem.value = response.data.data.data
          totalCategories.value = response.data.data.total

          // callback(data);
        })
    }
    const refetchData = () => {
      refCourseListTable.value.refresh()
    }
    watch([currentPage, perPage, searchQuery], () => {
      GetAllCourses()
    })
    const save = () => {
      const formData = new FormData()

      formData.append('selected_courses', JSON.stringify(selected.value))

      store
        .dispatch('courses/generateCourse', formData)
        .then(response => {
          Vue.swal({
            title: 'Course Generate ',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
        .catch(error => {
          Vue.swal({
            title: '',
            text: `${error.response.data.message}`,
            icon: 'error',
            confirmButtonText: 'حسنا',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    }
   
    GetAllCourses()
    const dataMeta = computed(() => {
      const localItemsCount = refCourseListTable.value
        ? refCourseListTable.value.localItems.length
        : 0
      return {
        from:
          perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalCategories.value,
      }
    })
    // GetAllCourses()
    const DeleteCourse = id => {
      Vue.swal({
        icon: 'warning',
        title: 'هل انت متاكد من الحذف ?',
        confirmButtonText: 'نعم',
        cancelButtonText: 'الغاء',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      }).then(result => {
        if (result.value) {
          store.dispatch('courses/DeleteGenerateCourse', id).then(response => {
            GetAllCourses()
            Vue.swal({
              title: 'تم الحذف  ',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          })
        }
      })
    }
    const tableColumns = [
      { key: 'id', label: 'الرقم', sortable: true },
      { key: 'name', label: 'اسم الدورة', sortable: true },
      { key: '', label: 'اختيار', sortable: true },
      { key: 'category.type', label: 'الفئة', sortable: true },
      // { key: "actions" },
    ]

    return {
      tableColumns,
      coursesList,
      DeleteCourse,
      save,
      meta,
      allItem,
      perPageOptions,
      totalCategories,
      currentPage,
      perPage,
      selected,
      dataMeta,
      Genertae,
      refCourseListTable,
      refetchData,
      GetAllCourses,

      searchQuery,
    }
  },
  directives: {
    Ripple,
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
