<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="6">
            <b-button
              variant="outline-primary"
              @click="selectAllRows"
            >
              اختر الكل
            </b-button>
          </b-col>
          <b-col
            v-if="$can('delete', 'course')"
            cols="6"
            class="d-flex align-items-end justify-content-end mb-1"
          >
            <b-button
              variant="primary"
              class="mr-1"
              @click="DeleteRows"
            >
              حذف الدورة المختارة
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-row class="mb-1 mt-1">
            <b-col cols="3">     <b-form-input

              v-model="searchQueryCN"
              class="d-inline-block mr-1"
              placeholder="البحث في اسم الدورة ..."
            /></b-col>
            <b-col cols="3">     <b-form-input

              v-model="searchQueryL"
              class="d-inline-block mr-1"
              placeholder="البحث عبر الرابط"
            /></b-col>
            <b-col cols="3">
              <b-form-input

                v-model="searchQueryC"
                class="d-inline-block mr-1"
                placeholder="البحث عبر الفئة"
              /></b-col>

            <b-col cols="3">     <b-form-input

              v-model="searchQueryD"
              class="d-inline-block mr-1"
              placeholder="البحث  في المدة"
            /></b-col>
          </b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="12"
            class="
              d-flex
              align-items-center
              justify-content-start
              mb-1 mb-md-0
            "
          >
            <label>عرض</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <!-- <label>SortBy</label>
              <v-select
              style="width:200px"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="sortOptions"
                v-model="sort"
                multiple
                :clearable="true"
                 /> -->

          </b-col>

        </b-row>
      </div>

      <b-table
        ref="refCourseListTable"
        class="position-relative"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :items="allData"
        select-mode="multi"
        selectable
        show-empty
        :sort-desc.sync="isSortDirDesc"
        empty-text="  يتم جلب البيانات"
        @row-selected="onRowSelected"
      >
        <!-- Column: Status -->

        <!-- Column: Actions -->
        <template #cell(actions)="data">

          <a
            target="_blank"
            :href="`https://api.ar.lpcentre.com/${data.item.link_id}`"
          >

            <feather-icon icon="LinkIcon" />

          </a>
          <a>
            <router-link

              :to="`/update-course/${ data.item.id}`"
              style="margin: 0px 12px"
            >
              <feather-icon icon="EditIcon" />
            </router-link>
          </a>
          <a
            v-if="$can('delete', 'course')"
            role="button"
            @click="DeleteCourse(data.item.id)"
          >
            <feather-icon icon="TrashIcon" />

          </a>

        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted">
              عرض {{ dataMeta.from }} الى {{ dataMeta.to }} من {{ dataMeta.of }} المدخلات
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalCategories"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,

  BPagination,

} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  ref, computed, watch, reactive,
} from '@vue/composition-api'
import Vue from 'vue'
import store from '@/store'
// import useCousresList from './useCousresList'
// import userStoreModule from '../userStoreModule'

export default {
  components: {
    // useCousresList,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,

    BPagination,

    vSelect,
  },
  setup() {
    const coursesList = ref([])
    const meta = reactive({})
    const perPageOptions = [25, 50, 100, 200]
    const perPage = ref(10)
    const refCourseListTable = ref(null)
    const searchQueryCN = ref('')
    const searchQueryC = ref('')
    const searchQueryL = ref('')
    const allData = ref([])
    const sortOptions = ['name', 'duration']
    const sort = ref('')
    const searchQueryD = ref('')
    const totalCategories = ref(0)
    const searchQuery = ref('')
    const currentPage = ref(1)
    const SortArray = []
    const GetAllCourses = () => {
      let data = null
      if (!SortArray.includes(sort.value) && sort.value != '') {
        if (isSortDirDesc.value == true) {
          SortArray.push(sort.value)
          data = SortArray.toString().replace('[', '')
        } else {
          SortArray.push(`-${sort.value}`)
          data = SortArray.toString().replace('[', '')
        }
      }

      store
        .dispatch('courses/getAllCoursesOnline', {
          'filter[name]': searchQueryCN.value,
          'filter[category]': searchQueryC.value,
          'filter[duration]': searchQueryD.value,
          'filter[link_id]': searchQueryL.value,

          sort: data,

          per_page: perPage.value,
          page: currentPage.value,
        })
        .then(response => {
          // coursesList.value=response.data.data
          allData.value = response.data.data
          totalCategories.value = response.data.total
        })
    }

    const isSortDirDesc = ref(true)
    const refetchData = () => {
      refCourseListTable.value.refresh()
    }
    watch([currentPage, sort, perPage, searchQueryD,
      searchQueryC,
      searchQueryCN, searchQueryL], () => {
      GetAllCourses()
    })

    GetAllCourses()

    const dataMeta = computed(() => {
      const localItemsCount = refCourseListTable.value
        ? refCourseListTable.value.localItems.length
        : 0
      return {
        from:
          perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalCategories.value,
      }
    })
    const selectAllRows = () => {
      if (!selectedRow.value.length > 0) {
        refCourseListTable.value.selectAllRows()
      } else {
        refCourseListTable.value.clearSelected()
      }
    }
    // GetAllCourses()
    const DeleteCourse = id => {
      Vue.swal({
        icon: 'warning',
        title: 'هل انت متاكد من الحذف ?',
        confirmButtonText: 'نعم',
        cancelButtonText: 'الغاء',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      }).then(result => {
        if (result.value) {
          store.dispatch('courses/DeleteCourse', id).then(response => {
            GetAllCourses()
            Vue.swal({
              title: 'تم الحذف  ',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          })
        }
      })
    }
    const selectedRow = ref([])
    const onRowSelected = items => {
      selectedRow.value = items
    }
    const DeleteRows = () => {
      const data = []
      selectedRow.value.forEach(el => {
        data.push(el.id)
      })
      Vue.swal({
        icon: 'warning',
        title: 'هل انت متاكد من الحذف ?',
        confirmButtonText: 'نعم',
        cancelButtonText: 'الغاء',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      }).then(result => {
        if (result.value) {
          store.dispatch('courses/DeleteSelectedCourses', data).then(response => {
            GetAllCourses()
            Vue.swal({
              title: 'تم الحذف  ',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          })
        }
      })
    }
    const tableColumns = [
      // { key: 'id',lanel:"ID", sortable: true },
      { key: 'id', label: 'الرقم', sortable: true },
      { key: 'name', label: 'اسم الدورة', sortable: true },
      { label: 'الرابط', key: 'link_id', sortable: true },
      {
        key: 'duration',
        label: 'المدة',

        sortable: true,
      },
      { key: 'category.type', label: 'الفئة', sortable: true },
      { key: 'actions', label: 'الخيارات' },
    ]

    return {
      onRowSelected,
      selectedRow,
      SortArray,
      isSortDirDesc,
      searchQueryL,
      DeleteRows,
      searchQueryD,
      searchQueryC,
      searchQueryCN,
      selectAllRows,
      tableColumns,
      coursesList,
      DeleteCourse,
      allData,
      meta,
      perPageOptions,
      totalCategories,
      currentPage,
      perPage,
      dataMeta,
      refCourseListTable,
      refetchData,
      GetAllCourses,
      searchQuery,
      sortOptions,
      sort,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
